import { CodeSamples } from 'components/markdown/code-samples';
import type { ComponentProps } from 'react';
import { useIntl } from 'react-intl';

export type StepSamplesProps = {
  requestSamples: ComponentProps<typeof CodeSamples>['samples'];
  responseSamples?: ComponentProps<typeof CodeSamples>['samples'];
};

const StepSamples = ({ requestSamples, responseSamples }: StepSamplesProps) => {
  const t = useIntl();

  return (
    <div data-testid="step-samples" className="flex flex-col gap-6">
      <CodeSamples
        heading={t.formatMessage({
          id: 'apiDoc.requestParams.requestExampleTitle',
        })}
        codeSampleHeading={t.formatMessage({
          id: 'apiDoc.requestParams.requestCodeExampleTitle',
        })}
        samples={requestSamples}
      />

      {responseSamples && responseSamples.length > 0 && responseSamples[0].source && (
        <CodeSamples
          heading={t.formatMessage({
            id: 'apiDoc.responses.responseExampleTitle',
          })}
          codeSampleHeading={t.formatMessage({
            id: 'apiDoc.responses.responseCodeExampleTitle',
          })}
          samples={responseSamples}
        />
      )}
    </div>
  );
};

export default StepSamples;
