import { LanguageCode, type WorkflowStep } from '@newdaycards/spec-tools';
import { Text } from 'components/typography/text';
import { TabContent, TabTrigger, Tabs, TabsList } from 'components/ui/tabs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineSpaceDashboard } from 'react-icons/md';
import { StepList } from '../components/workflow';
import StepSamples from '../components/workflow/step-samples';
import { type ImageNode, useContentImage } from '../hooks';
import { sendAnalytics } from '../utils/analytics/google';

export interface WorkflowDocProps {
  workflow: {
    workflowId: string;
    summary: string;
    description: string;
    steps: WorkflowStep[];
  };
}

export const WorkflowLayout = ({ workflow }: WorkflowDocProps) => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<string>('samples');
  const images = useContentImage('', 'multiple', 'workflow');
  const currentStep = workflow.steps[currentStepIndex];

  const stepsImages = useMemo(() => {
    return workflow.steps.map((step) =>
      images.find(({ relativePath }: { relativePath: string }) =>
        relativePath.includes(step.image || step.stepId.toLowerCase()),
      ),
    ) as ImageNode[];
  }, [workflow, images]);

  const handleOnStepChange = useCallback(
    (stepIndex: number) => {
      setCurrentStepIndex(stepIndex);
      sendAnalytics('workflowStepChange', {
        workflowId: workflow.workflowId,
        stepId: workflow.steps[stepIndex].stepId,
      });
    },
    [workflow],
  );

  const handleOnValueChange = (value: string) => {
    setActiveTab(value);
    sendAnalytics('workflowTabChange', { workflowId: workflow.workflowId, stepId: currentStep.stepId, tab: value });
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: only when the image changes
  useEffect(() => {
    if (!stepsImages[currentStepIndex]) setActiveTab('samples');
  }, [currentStepIndex]);

  return (
    <div data-testid="workflow-layout">
      <div className="mb-8">
        <h1 className="text-2xl">{workflow.summary}</h1>
        <Text className="mt-2">{workflow.description}</Text>
      </div>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div className="flex flex-col rounded-lg bg-neutral-100 p-8 dark:bg-dark-800">
          <StepList steps={workflow.steps} currentStepIndex={currentStepIndex} onStepChange={handleOnStepChange} />
        </div>
        <div>
          <Tabs className="sticky top-[100px]" value={activeTab} onValueChange={handleOnValueChange}>
            <TabsList className="mb-5 flex gap-2">
              <TabTrigger className="px-4 py-2" value="samples" data-testid="workflow-samples-tab">
                <MdKeyboardArrowLeft className="-ml-2 -mr-3 size-6" /> <MdKeyboardArrowRight className="mr-1	size-6" />{' '}
                Requests
              </TabTrigger>
              {stepsImages[currentStepIndex] && (
                <TabTrigger className="px-4 py-2" value="image" data-testid="workflow-image-tab">
                  <MdOutlineSpaceDashboard className="mr-2 size-6" /> User Interface
                </TabTrigger>
              )}
            </TabsList>
            <TabContent value="samples">
              {currentStep.requestSamples && (
                <StepSamples
                  requestSamples={currentStep.requestSamples}
                  responseSamples={[
                    {
                      title: 'JSON',
                      lang: LanguageCode.javascript,
                      source: JSON.stringify(currentStep.outputs?.content?.['application/json']?.example, null, 2),
                    },
                  ]}
                />
              )}
            </TabContent>
            {stepsImages[currentStepIndex] && (
              <TabContent value="image" className="flex justify-center">
                <img
                  data-testid="workflow-step-image"
                  src={stepsImages[currentStepIndex].publicURL}
                  alt="Current step"
                  className="h-full max-h-[calc(100vh-168px)]"
                />
              </TabContent>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
};
