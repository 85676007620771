import type { WorkflowObject } from '@newdaycards/spec-tools';
import { StepFilters } from 'components/workflow';
import type { PageProps } from 'gatsby';
import withAppInsights from 'utils/analytics/appinsights';
import { PageLayout } from '../layouts/page-layout';
import { WorkflowLayout } from '../layouts/workflow';

export interface WorkflowPageContext {
  workflow: WorkflowObject;
  title: string;
  description: string;
  commitDate: number;
}

export function WorkflowTemplate({ pageContext, location }: PageProps<null, WorkflowPageContext>) {
  const { title, description, workflow, commitDate } = pageContext;

  return (
    <PageLayout location={location} isLargeContent title={title} description={description} commitDate={commitDate}>
      <StepFilters pathname={location.pathname} />
      <WorkflowLayout workflow={workflow} />
    </PageLayout>
  );
}

export default withAppInsights(WorkflowTemplate);
