import type { SpecMethod } from '@newdaycards/spec-tools';
import { Heading } from 'components/typography/heading';
import { Text } from 'components/typography/text';
import { Badge } from 'components/ui/badge';
import { Tag } from 'components/ui/tag';
import type { HTMLAttributes, MouseEvent } from 'react';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { cn } from 'utils/cn';
import { CopyButton } from '../../feature/copy-button';
import { CopyLinkButton } from '../../feature/copy-button/copy-link-button';

export interface EndpointInfoProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  method: string;
  path: string;
  description?: string;
  id?: string;
  deprecated?: boolean;
  href?: string;
  onLinkClick?: (event: MouseEvent<HTMLElement>) => void;
}

export const EndpointInfo = ({
  title,
  method,
  path,
  description,
  id,
  deprecated,
  className,
  href,
  onLinkClick,
}: EndpointInfoProps) => (
  <div className={cn('mb-6 flex flex-col items-start gap-4 xl:flex-row', className)} data-testid="endpoint-info">
    <div className="max-w-full basis-1/2">
      <div className="mb-1 flex items-center gap-2">
        <Heading as="h3" className="mt-0 truncate">
          {title}
        </Heading>
        {!!href && (
          <a
            href={href}
            aria-label={`Documentation for ${title} - opens in a new tab`}
            target="_blank"
            rel="noreferrer noopener"
            onClick={onLinkClick}
          >
            <MdOutlineOpenInNew className="size-6" />
          </a>
        )}
        {deprecated && <Badge variant="deprecated">Deprecated</Badge>}
        {!href && <CopyLinkButton id={id} className="flex-shrink-0" />}
      </div>
      {description && (
        <Text variant="secondary" className="text-sm">
          {description}
        </Text>
      )}
    </div>
    <div className="w-full basis-1/2 overflow-auto rounded-md border-[1px] border-neutral-300 bg-white dark:border-dark-500 dark:bg-dark-800">
      <div className="flex items-center gap-2 p-2" data-pagefind-ignore="all">
        <Tag variant={method.toLowerCase() as SpecMethod}>{method}</Tag>
        <Text className="truncate break-words font-mono text-sm font-medium">{path}</Text>
        <span className="grow" />
        <CopyButton
          copyString={path}
          variant="ghost"
          config={{ isShown: true }}
          aria-label="Copy endpoint path"
          className="flex items-center p-0 h-fit"
        />
      </div>
    </div>
  </div>
);
