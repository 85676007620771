import { type EnrichedSchema, type WorkflowStep, getContentType } from '@newdaycards/spec-tools';
import { EndpointInfo } from 'components/reference/endpoint/endpoint-info';
import { EnumValues } from 'components/reference/param-types/param-enum';
import { Text } from 'components/typography/text';
// biome-ignore lint/style/useImportType: <explanation>
import { OpenAPIV3 } from 'openapi-types';
import { type MouseEvent, useMemo } from 'react';
import { cn } from 'utils/cn';
import { sendAnalytics } from '../../../utils/analytics/google';

interface EnrichedSchemaObjectProperties {
  [name: string]: EnrichedSchema;
}

const EndpointSchema = ({
  schema,
}: {
  schema: EnrichedSchemaObjectProperties;
}) => (
  <div data-testid="step-endpoint-schema" className="flex flex-col gap-4 pt-4">
    {schema &&
      Object.entries(schema).map(([name, values]) => (
        <div key={name}>
          <div className="flex items-center gap-2">
            <Text className="font-mono text-sm font-bold">{name}</Text>
            <span>•</span>
            <div className="text-sm text-blue-700 dark:text-blue-500">{`${values.type} ${values.enum ? 'enum' : ''}`}</div>
          </div>
          {values.description && (
            <Text className="my-4 text-sm text-neutral-600 dark:text-dark-200">{values.description}</Text>
          )}
          <div className="mt-2 overflow-hidden rounded-md border border-neutral-200 dark:border-dark-600">
            <div className="gap-2 bg-neutral-200 p-2 dark:bg-dark-850">
              <Text className="font-mono text-sm font-medium">Enum values</Text>
            </div>
            {values.enum && values['x-enumNames'] && (
              <EnumValues name={name} enumValues={values.enum} enumLabels={values['x-enumNames']} />
            )}
          </div>
        </div>
      ))}
  </div>
);

export type StepCardProps = {
  step: WorkflowStep;
  stepIndex: number;
  active?: boolean;
  onClick: (stepIndex: number) => void;
};

const StepCard = ({ step, stepIndex, active, onClick }: StepCardProps) => {
  const selectedProperties = useMemo(() => {
    const contentType = getContentType(step.outputs.content);
    const schema = step.outputs.content?.[contentType]?.schema as OpenAPIV3.BaseSchemaObject;
    const { enumFilter } = step;
    const selectedProperties: EnrichedSchemaObjectProperties =
      Object.entries((schema?.properties || {}) as EnrichedSchemaObjectProperties).reduce(
        (properties, [propertyName, property]) => {
          if (propertyName !== 'state' && propertyName !== 'subState') return properties;
          return {
            ...properties,
            [propertyName]: {
              ...property,
              enum:
                enumFilter && property.enum
                  ? property.enum.filter((value) => enumFilter.includes(value))
                  : property.enum,
            },
          };
        },
        {},
      ) || {};
    return selectedProperties;
  }, [step, stepIndex]);

  const handleLinkClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    sendAnalytics('workflowStepLinkClicked', { stepId: step.stepId });
  };

  return (
    <div
      data-testid="step-card"
      className={cn(
        'cursor-auto relative rounded-md border border-neutral-300 p-6 outline-0 focus:shadow-[0_0_0_4px_#EDE9FE]',
        'after:absolute after:left-[calc(50%-1px)] after:top-[calc(100%+4px)] after:z-0 after:block after:h-7 after:w-px after:border-l after:border-dashed after:border-neutral-300 after:last:content-none dark:after:border-dark-700',
        active && 'border-purple-300 after:top-[calc(100%+5px)] hover:shadow-[0_0_0_4px_#EDE9FE]',
        !active &&
          'cursor-pointer before:absolute before:bottom-0 before:left-0 before:right-0 before:top-0 before:z-0 before:rounded-md before:bg-neutral-200 before:opacity-60 before:transition-opacity before:duration-300 before:ease-in-out before:hover:opacity-30 before:focus:opacity-30 dark:border-dark-500 dark:before:bg-dark-600',
      )}
      role="button"
      tabIndex={0}
      onClick={() => onClick(stepIndex)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClick(stepIndex);
        }
      }}
    >
      <EndpointInfo
        method={step.operationType}
        path={step.operationPath}
        title={step.stepId}
        id={step.stepId}
        description={step.description}
        className="m-0 xl:flex-col"
        href={step.operationLink}
        onLinkClick={handleLinkClick}
      />
      {active && Object.keys(selectedProperties).length >= 1 && <EndpointSchema schema={selectedProperties} />}
    </div>
  );
};

export default StepCard;
