import * as TabsPrimitive from '@radix-ui/react-tabs';
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from 'react';
import { cn } from 'utils/cn';

const Tabs = TabsPrimitive.Root;
const TabsList = TabsPrimitive.List;
const TabContent = TabsPrimitive.Content;

const TabTrigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    {...props}
    className={cn(
      'flex items-center rounded bg-neutral-100 px-4 py-2 text-sm font-medium leading-5 text-neutral-600',
      'hover:bg-neutral-200 hover:text-neutral-700',
      'dark:bg-dark-900 dark:text-dark-200',
      'dark:hover:bg-dark-700 dark:hover:text-dark-300',
      'aria-selected:bg-purple-200 aria-selected:text-purple-700',
      'aria-selected:hover:bg-purple-300',
      'aria-selected:hover:text-purple-800',
      'dark:aria-selected:bg-purple-800 dark:aria-selected:text-purple-100',
      'dark:aria-selected:hover:bg-purple-900 dark:aria-selected:hover:text-purple-50',
      className,
    )}
  />
));

export { TabContent, TabTrigger, Tabs, TabsList };
