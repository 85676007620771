import type { WorkflowStep } from '@newdaycards/spec-tools';
import StepCard from '../step-card';

export type StepListProps = {
  steps: WorkflowStep[];
  onStepChange: (stepIndex: number) => void;
  currentStepIndex: number;
};

const StepList = ({ steps, onStepChange, currentStepIndex }: StepListProps) => (
  <div data-testid="steps-list" aria-label="Workflow steps" className="flex flex-col gap-8">
    {steps.map((step, index) => (
      <StepCard
        step={step}
        stepIndex={index}
        active={currentStepIndex === index}
        onClick={onStepChange}
        key={`step${index + 1}-${step.stepId}`}
      />
    ))}
  </div>
);
export default StepList;
