import { Text } from 'components/typography/text';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';
import { useState } from 'react';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { cn } from 'utils/cn';

export type ParamEnumProps = {
  name: string | undefined;
  enumValues: (string | number)[];
  enumLabels?: string[];
};

export const EnumValues = ({ name, enumValues, enumLabels }: ParamEnumProps) => (
  <>
    {enumValues.map((enumVal, idx) => (
      <div key={`${name}-${enumVal}`} className="p-2 border-t border-neutral-200 dark:border-dark-600">
        <Text variant="code" className="mr-6 inline-flex text-xs font-medium px-1 py-[2px]">
          {enumVal}
        </Text>
        <span className="text-sm">{enumLabels?.[idx]}</span>
      </div>
    ))}
  </>
);

export const ParamEnum = ({ name, enumValues, enumLabels }: ParamEnumProps) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState('');

  return (
    <Accordion type="single" collapsible data-testid="param-enum" value={open} onValueChange={setOpen}>
      <AccordionItem value="enum-accordion">
        <AccordionTrigger
          variant="empty"
          className={cn(
            'mt-2 h-auto justify-start gap-2 bg-neutral-200 p-2 dark:bg-dark-850',
            open && 'rounded-b-none',
          )}
          aria-label={
            open
              ? `Hide ${formatMessage({
                  id: 'apiDoc.common.enum.possibleValues',
                })}`
              : `Show ${formatMessage({
                  id: 'apiDoc.common.enum.possibleValues',
                })}`
          }
        >
          {open ? (
            <MdRemoveCircleOutline data-testid="hide-icon" className="size-[14px]" />
          ) : (
            <MdAddCircleOutline className="size-[14px]" data-testid="show-icon" />
          )}
          <Text className="text-sm font-mono font-medium">
            {open
              ? `Hide ${formatMessage({
                  id: 'apiDoc.common.enum.possibleValues',
                })}`
              : `Show ${formatMessage({
                  id: 'apiDoc.common.enum.possibleValues',
                })}`}
          </Text>
        </AccordionTrigger>
        <AccordionContent className="rounded-b-md border border-neutral-200 bg-white pb-0 dark:border-dark-600 dark:bg-dark-800">
          <EnumValues name={name} enumValues={enumValues} enumLabels={enumLabels} />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default ParamEnum;
