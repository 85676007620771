import { navigate } from 'gatsby';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import Select, { type SingleValue } from 'react-select';
import { cn } from 'utils/cn';
import { useWorkflowsData } from '../../../hooks';
import { sendAnalytics } from '../../../utils/analytics/google';

const styles = {
  option: () => ({}),
  control: () => ({}),
  menu: () => ({}),
  indicatorsContainer: () => ({}),
  singleValue: () => ({}),
  valueContainer: () => ({}),
};

const classNames = {
  container: () => 'flex-1',
  input: () => 'min-w-0 p-0 m-0 text-neutral-900 dark:text-dark-50',
  placeholder: () => 'text-neutral-600 dark:text-dark-300',
  option: (state: any) =>
    cn(
      'flex items-center gap-2 rounded-lg my-1 mx-2 p-2 w-auto cursor-pointer bg-white dark:bg-dark-800',
      'hover:bg-neutral-100 hover:dark:bg-dark-850 active:bg-neutral-100 active:dark:bg-dark-850',
      `lg:${!state.isSelected && state.isFocused ? 'bg-neutral-100' : 'bg-white'} dark:lg:${!state.isSelected && state.isFocused ? 'bg-dark-850' : 'bg-dark-800'}`,
    ),
  control: (state: any) =>
    cn(
      'flex min-h-[38px] items-center bg-white dark:bg-dark-800 rounded-lg border',
      'hover:cursor-pointer border-neutral-500 dark-border-dark-600',
      `${state.menuIsOpen ? 'border-purple-300 dark:border-purple-600 ring-4 ring-purple-100 dark:ring-purple-600' : 'border-neutral-300 dark:border-dark-700'}`,
    ),
  menu: () =>
    'absolute top-full w-full bg-white dark:bg-dark-800 border border-neutral-200 dark:border-dark-600 rounded-lg shadow my-2',
  indicatorsContainer: () => 'flex items-center shrink-0 text-neutral-600 dark:text-dark-300 text-xl',
  singleValue: () => 'w-fit overflow-hidden ellipsis whitespace-nowrap',
  valueContainer: () =>
    'flex flex-1 items-center gap-2 overflow-hidden py-0.5 px-2 text-neutral-600 dark:text-dark-300',
};

export type StepFiltersProps = {
  pathname: string;
};

type WorkflowOption = {
  label: string;
  value: string;
  link: string;
};

const StepFilters = ({ pathname }: StepFiltersProps) => {
  const workflows = useWorkflowsData();
  const t = useIntl();
  const [selectedWorkflow, setSelectedWorkflow] = useState<WorkflowOption | undefined>();
  const [selectedJourney, setSelectedJourney] = useState<WorkflowOption | undefined>();
  const workflowFilters = useMemo(
    () =>
      workflows.map((workflowSet) => ({
        label: workflowSet.heading,
        value: workflowSet.heading,
        link: workflowSet.link,
        options: workflowSet?.workflows?.map((workflowItem) => ({
          label: workflowItem.summary,
          value: workflowItem.workflowId,
          link: `${workflowSet.link}/${workflowItem.workflowId.toLowerCase().replaceAll(' ', '-')}`,
        })),
      })),
    [workflows],
  );

  useEffect(() => {
    const currentWorkflow = workflowFilters.find((option) => pathname.includes(option.link));
    const currentJourney = currentWorkflow?.options.find((option) => pathname.includes(option.link));
    if (currentWorkflow?.value !== selectedWorkflow?.value || currentJourney?.value !== selectedJourney?.value) {
      setSelectedWorkflow(currentWorkflow);
      setSelectedJourney(currentJourney);
    }
  }, [pathname, workflowFilters, selectedWorkflow?.value, selectedJourney?.value]);

  const workflowOptions = useMemo(
    () => workflowFilters.map(({ options, ...keptProperties }) => keptProperties),
    [workflowFilters],
  );
  const journeyOptions = useMemo(
    () => workflowFilters.find((option) => option.value === selectedWorkflow?.value)?.options,
    [workflowFilters, selectedWorkflow],
  );

  const handleOnJourneyTypeChange = (value: SingleValue<{ label: string; value: string; link: string }>) => {
    if (value?.value) {
      sendAnalytics('workflowFilter', {
        filterType: 'journeyType',
        filterValue: value.value,
      });
    }
    const selectedWorkflowOption = workflowFilters.find((option) => option.value === value?.value);
    const firstJourneyLink = selectedWorkflowOption ? `${selectedWorkflowOption.options[0].link}` : '';
    navigate(firstJourneyLink);
  };

  const handleOnJourneyChange = (value: SingleValue<{ label: string; value: string; link: string }>) => {
    if (value?.value) {
      sendAnalytics('workflowFilter', {
        filterType: 'journey',
        filterValue: value.value,
      });
    }
    const journeyLink = `${value?.link}`;
    navigate(journeyLink);
  };

  return (
    <div
      data-testid="step-filters"
      className="sticky top-0 z-[1] mx-auto mb-9 flex w-full flex-col justify-between gap-4 border-b border-neutral-200 bg-white py-6 md:flex-row dark:border-dark-600 dark:bg-dark-900"
    >
      <Select
        aria-label={t.formatMessage({ id: 'workflows.workflow' })}
        styles={styles}
        classNames={classNames}
        options={workflowOptions}
        value={selectedWorkflow}
        placeholder={t.formatMessage({ id: 'workflows.workflow' })}
        onChange={handleOnJourneyTypeChange}
        components={{ IndicatorSeparator: () => null }}
      />
      <Select
        aria-label={t.formatMessage({ id: 'workflows.journey' })}
        styles={styles}
        classNames={classNames}
        options={journeyOptions}
        placeholder={t.formatMessage({ id: 'workflows.journey' })}
        value={selectedJourney}
        onChange={handleOnJourneyChange}
        components={{ IndicatorSeparator: () => null }}
      />
    </div>
  );
};

export default StepFilters;
